.root {
    height: 94vh;
    width: 98vw;
    background: #fff;
    overflow: hidden;
}

.image {
    background: url('../../assets/images/logo.png');
    background-repeat: no-repeat;
    background-color: #fff;
    position: relative;
    top: 20%;
    left: 30%;
    transform: translate(-50%, 0%);
    background-size: contain;
}

.loginCard {
    max-width: 400px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.paper {
    margin: 8px 4px;
    padding: 15px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    margin: 10px;
    background: #1976d2 !important;
}

form {
    width: 100%;
    margin-top: 10px;
}

.submit {
    margin-top: 15px !important;
}

.MuiInputBase-root {
    text-align: left !important;
}

.MuiFormControl-root {
    margin: 7px 0px !important;
}