.image {
    background: url('../../assets/images/logo.png');
    background-repeat: no-repeat;
    background-color: #fff;
    position: relative;
    top: 20%;
    left: 30%;
    transform: translate(-50%, 0%);
    background-size: contain;
}

.title-here {
    font-size: 22px;
    font-weight: 600;
    margin-left: 5px;
}

.ant-card-body {
    padding: 12px;
    text-align: left;
}

.ant-card-meta-description p {
    margin: 5px 0px !important;
}

.ant-card-meta-description span {
    margin-top: 5px !important;
}

/* .ant-card {
    box-shadow: 0 0 5px 0px rgb(0 0 0 / 25%);
} */

.ant-card {
    border-left: none;
    border-right: none;
}

.anticon {
    font-size: 16px !important;
}

#JobDetails {
    height: 75vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.ant-table-wrapper {
    width: 100% !important;
}

.active-card {
    box-shadow: rgb(0 0 0 / 30%) 0px -2px 30px -15px inset;
}

.ant-card-actions li {
    margin: 6px 0px !important;
}

.ant-card-actions .anticon:hover {
    color: #b32800 !important;
}

.sequence-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    gap: 20px;
}


.path { stroke-dasharray: 1000; stroke-dashoffset: 1000; animation: dash 3s linear forwards; animation-iteration-count: 1;animation-delay: 1s;
    stroke: #b32800;}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.dashed{
  stroke-dasharray: 5,12;
}
#arrow{animation: arrow 2s linear forwards; opacity:0; fill: #b32800}
@keyframes arrow {
  to {
    opacity: 1;
  }
}
.ifrm {
    background-color: #b32800;
    color: #fff;
    margin-left: 10px;
}

.ifrm:hover {
    background-color: #fff;
    color: #b32800 !important;
    border-color: #b32800 !important;
    margin-left: 10px;
}

.graph__wrapper {
    display: flex;
    justify-content: flex-start;
}

.div-down {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:50px;
    padding-right: 50px;
    animation: fadeInAnimation ease 5s;
    -webkit-animation: fadeInAnimation ease 5s;
    -moz-animation: fadeInAnimation ease 5s;
    -o-animation: fadeInAnimation ease 5s;
    -ms-animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-delay: 5s;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ant-alert-close-icon {
    margin-top: -10px;
    margin-right: -15px;
}

.ant-alert-icon {
    font-size: 24px !important;
}

.zoom-in-out-box {
  animation: zoom-in-zoom-out 1.5s ease infinite !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin-left: 15px !important;
  border-radius: 2px !important;
  color: #fff !important;
  background-color: #b32800 !important;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.whatsapp-builder::before {
    content: "";
    background: url('/public/preview.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #e5ddd5;
    opacity: 0.2;
}

.whatsapp-builder {
    position: relative;
    z-index: 1;
    overflow-y: auto;
}

.whatsapp-builder-button {
    text-transform: capitalize;
    text-align: left;
    color: black;
    background: lightgrey;
    border: 2px dashed black;
    width: 90%;
    padding: 12px;
    margin: 10px auto;
    display: block;
    opacity: 0.7;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    z-index: 99999
}

.whatsapp-builder-button2 {
    text-transform: capitalize;
    text-align: right;
    color: blue;
    margin-top: 0px;
    display: block;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    z-index: 99999
}

.option-button {
    width: 84%;
    text-align: center;
    background: #fff;
    position: relative;
    margin: -5px auto 7.5px auto !important;
    border-radius: 8px;
    color: #00a5f4;
    font-size: 15px;
    line-height: 20px;
    padding: 5px 16px;
}

.msg-body {
    width: 86%;
    text-align: left;
    background: #fff;
    white-space: pre-wrap;
    position: relative;
    margin: 5px auto 10px auto !important;
    border-radius: 8px;
    color: #000000de;
    font-size: 15px;
    line-height: 20px;
    padding: 5px 12px;
    cursor: pointer;
}