.root {
    padding: 30px !important;
    font-family: 'Open Sans' !important;
}

.image {
    background: url('../../assets/images/logo.png');
    background-repeat: no-repeat;
    background-color: #fff;
    position: relative;
    top: 20%;
    left: 30%;
    transform: translate(-50%, 0%);
    background-size: contain;
}

.candidatesItems {
    height: 52px !important;
    padding: 12px 10px 0px 10px;
    display: flex !important;
    border-bottom: 1px solid rgb(218, 221, 225);
    background-color: white !important;
}

.avatar {
    margin: 10px !important;
    height: 30px !important;
    width: 30px !important;
}

.inputRoot {
    padding: 2px 4px !important;
    display: flex !important;
    align-items: center !important;
}

.input {
    margin-left: 10px !important;
    flex: 1 !important;
}

.iconButton {
    padding: 10px !important;
    color: #0779e4 !important;
}

.expand {
    transform: rotate(0deg) !important;
}

.expandOpen {
    transform: rotate(45deg) !important;
}

.optionNav {
    margin-top: auto !important;
    position: relative !important;
}

.optionBox {
    background-color: wheat !important;
    color: #000 !important;
    position: absolute !important;
    left: 10px !important;
    padding: 0px !important;
    border-radius: 16px !important;
    right: 10px !important;
    bottom: -20px !important;
}

.optionAction {
    color: #fff !important;
}

.divider {
    height: 28px !important;
    margin: 4px !important;
}

.candidateName {
    text-align: left !important;
    margin: 5px 0px 0px 0px !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.status {
    text-align: left !important;
    margin-left: 10px !important;
    margin-top: 5px !important;
    font-size: 11px !important;
    color: green !important;
    font-weight: bold !important;
}

.companyName {
    text-align: left !important;
    margin-top: 0px !important;
    font-size: 12px !important;
}

.details {
    text-align: right !important;
    margin: 20px !important;
    font-size: 13px !important;
    color: #0779e4 !important;
    font-weight: bold !important;
}

.message {
    text-align: left !important;
    padding: 15px 15px 10px 15px;
    max-width: 280px !important;
    margin: 10px !important;
    word-break: break-word;
    border-radius: 10px !important;
    display: flex !important;
    font-size: 14px !important;
    white-space: pre-line;
    justify-content: space-between;
    align-items: baseline;
}

.message .mess-timestamp {
    float: right !important;
    color: gray;
    padding-left: 5px;
    font-size: 11px;
    margin-top: 10px;
    bottom: 0;
}

.messageRight .mess-timestamp {
    float: right !important;
    color: lightgray;
    padding-left: 5px;
    margin-top: 10px;
    font-size: 11px;
    bottom: 0;
}

.messageRight {
    text-align: left !important;
    padding: 15px 15px 10px 15px;
    float: right !important;
    word-break: break-word;
    max-width: 280px !important;
    margin: 10px !important;
    border-radius: 15px !important;
    display: flex !important;
    justify-content: space-between;
    background-color: #0779e4 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    white-space: pre-line;
    align-items: baseline;
}

.drawer {
    width: 360px !important;
    flex-shrink: 0 !important;
}

.drawerPaper {
    width: 360px !important;
}

.drawerHeader {
    display: flex !important;
    align-items: left !important;
    background-color: #efefef !important;
    padding: 10px !important;
    justify-content: flex-start !important;
}

embed {
    width: 100% !important;
    min-height: 65vh;
}

.inputRoot fieldset {
    border: none !important;
}

.inputRoot .MuiInputBase-root,
.inputRoot .MuiTextField-root {
    min-height: 42px !important;
}

.inputRoot .MuiInputBase-root {
    padding: 0px !important;
}

.active-button {
    text-transform: capitalize !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    width: 80px !important;
    color: #b32800 !important;
    /* background-color: #f7e1e1 !important; */
    border-bottom: 2px solid #b32800 !important;
    text-align: center !important;
    border-radius: 3px !important;
    padding: 3px 0px !important;
    outline: none;
}

.active-button-use {
    text-transform: capitalize !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    margin-left: 8px !important;
    width: 120px !important;
    color: #b32800 !important;
    /* background-color: #f7e1e1 !important; */
    border-bottom: 2px solid #b32800 !important;
    text-align: center !important;
    border-radius: 3px !important;
    padding: 3px 0px !important;
    outline: none;
}

.active-smallbutton {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 13px;
    color: #b32800;
    background-color: #f7e1e1;
    text-align: center;
    border-radius: 3px;
    padding: 0px 7px;
    width: fit-content;
    outline: none;
}

.active-button:hover, .inactive-button:hover, .inactive-button-use:hover, .active-button:hover {
    background-color: #f5d6d6 !important;
}

.active-smallbutton:hover, .inactive-smallbutton:hover {
    background-color: #f5d6d6 !important;
    color: #1c2b33 !important;
    border-color: #f5d6d6 !important;
}

.inactive-button {
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #1c2b33 !important;
    width: 80px !important;
    padding: 3px 0px !important;
    text-align: center !important;
    padding: 0px !important;
    outline: none;
}

.inactive-button-use {
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-left: 8px !important;
    color: #1c2b33 !important;
    width: 120px !important;
    padding: 3px 0px !important;
    text-align: center !important;
    padding: 0px 10px !important;
    outline: none;
}

.inactive-smallbutton {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;
    color: #1c2b33;
    padding: 0px 7px;
    text-align: center;
    width: fit-content;
    outline: none;
}

.filter-buttons {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.filter-buttons::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.candidate-list-div {
    height: 45px;
  display: flex;
  padding: 10px 0px;
  cursor: pointer;
}

.candidate-container {
    display: flex;
}

.candidate-container-name {
    font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  width: 20ch;
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
}

.candidate-timestamp {
    font-size: 12px;
  color: #00000099;
  margin: 0 10px 0 auto;
  display: flex;
  align-items: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.candidate-textread {
    display: block;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 35ch;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin: 2px 0;
}

.candidate-textunread {
    display: block;
  text-align: left;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 30ch;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin: 2px 0;
}

.MuiFab-circular {
    background-color: #fff !important;
    width: 40px !important;
    height: 40px !important;
    box-shadow: none !important;
}

.MuiFab-circular:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.MuiSpeedDialAction-staticTooltipLabel {
    width: max-content !important;
}

.MuiFab-circular:hover {
    background-color: white !important;
    color: #b32800;
}

.MuiTooltip-tooltip {
    background-color: #fff !important;
    color: #b32800 !important;
    font-size: 14px !important;
    padding: 5px !important;
}